var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalOverlay", { on: { close: _vm.onClose } }, [
    _c("div", { staticClass: "modal-tab-bar", attrs: { "data-cy": "modal" } }, [
      _c(
        "div",
        { staticClass: "fixed-width-container" },
        [
          _c("ModalHeader", {
            attrs: { title: _vm.title },
            on: { close: _vm.onClose }
          }),
          _vm._v(" "),
          _c("HorizontalSortTabBar", { attrs: { items: _vm.items } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }